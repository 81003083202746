import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
// import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  TableCell,
  TableRow,
  Tooltip,
  Table,
  TableHead,
  Button,
} from '@mui/material';
import { TableBodyData } from '../../types';
import { useState } from 'react';

import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { useNavigate } from 'react-router-dom';

export const TableBodyElement: React.FunctionComponent<TableBodyData> = (
  props: TableBodyData,
) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [dropIndex, setDropIndex] = useState<number>(0);
  const [dropData, setDropData] = useState<any>(null);

  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const tableColumnsData = (columnsData: any) => {
    const variableType: string[] = ['object'];
    if (variableType.includes(typeof columnsData)) {
      return columnsData ? (columnsData as string[]).join(', ') : '';
    }
    return columnsData;
  };
  let tableColumnWidth = props.styleFn;


  const ITEM_HEIGHT = 20;

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setDropIndex(Number(event?.currentTarget?.getAttribute('data-id')));
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClosee = () => {
    setAnchorEl(null);
  };

  const formatSubscriptionType = React.useCallback((data: any) => {
    let subscriptionType = data['subscription_type'];
    if (data['notificationType'] == ('CONSUMPTION_REQUEST')) {
      return 'Initiate Refund';
    }
    if (data['notificationType'] == ('REFUND')) {
      return 'Refund';
    }
    if (subscriptionType.includes('com.quintic.quinticballroll.monthly')) {
      return `Monthly ${data?.promo_code_appplied ? 'Promo' : ""}`;
    } else if (subscriptionType.includes('com.quintic.quinticballroll.yearly')) {
      return `Yearly ${data?.promo_code_appplied ? 'Promo' : ""}`;
    } else if (subscriptionType.includes('com.quintic.quinticballroll.quinticlifetime')) {
      return 'Lifetime';
    } else if (subscriptionType.includes('Free Trial')) {
      return 'Free';
    } else {
      return subscriptionType;
    }
  }, [])

  return (
    <>
      {props.setData?.map((column: any, index: number) => (
        <>
          <TableRow key={index}>
            {typeof props.dropFn === 'function' && (
              <TableCell align="center" style={{ width: '50px' }}>
                <span
                  onClick={(e: any) => {
                    setIsOpen(!isOpen);
                    setDropIndex(index);
                    setDropData(column);
                  }}
                >
                  {isOpen && index === dropIndex ? (
                    <KeyboardArrowUpIcon />
                  ) : (
                    <KeyboardArrowDownIcon />
                  )}
                </span>
              </TableCell>
            )}

            <TableCell>
              {props.pageData &&
                props.pageData?.limit * props.pageData?.page + index + 1}
            </TableCell>
            {props.selectedColumns.map((columnName, indx: number) => (
              <TableCell
                key={`cell-${indx}`}
                align="left"
                style={{ width: tableColumnWidth }}
              >
                {columnName === 'notificationType' ? (
                  ['EXPIRED', 'REFUND'].includes(tableColumnsData(column[columnName])) ? (
                    <div style={{ color: 'red' }}>
                      <CancelIcon />
                    </div>
                  ) : (
                    <div style={{ color: 'Green' }}>
                      <CheckCircleIcon />
                    </div>
                  )
                ) : (
                  columnName === 'subscription_type' ? formatSubscriptionType(column) :
                    tableColumnsData(column[columnName])
                )}
              </TableCell>
            ))}
            {props.showAction && <TableCell align="left">
              <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                data-id={index}
                onClick={handleClick}
              >
                <MoreVertIcon />
              </IconButton>
              {dropIndex === index && (
                <Menu
                  id="long-menu"
                  MenuListProps={{
                    'aria-labelledby': 'long-button',
                  }}
                  anchorEl={anchorEl}
                  open={open}
                  onClick={handleClose}
                  PaperProps={{
                    style: {
                      // maxHeight: ITEM_HEIGHT * 4.5,
                      width: '22ch',
                    },
                  }}
                >
                  {typeof props.respondFn === 'function' && (
                    <MenuItem>
                      {column.description ? (
                        <a
                          href={`mailto:?subject=Regarding your Query on Quintic Roll App : ${column.subject}&to=${column.email}&body=${column.description} : `}
                          target="_blank"
                          className="gmail"
                        >
                          <div>Respond</div>
                        </a>
                      ) : (
                        <a
                          href={`mailto:?subject=Regarding your Query on Quintic Roll App&to=${column.email}`}
                          target="_blank"
                          className="gmail"
                        >
                          <div>Respond</div>
                        </a>
                      )}
                    </MenuItem>
                  )}
                  {/* {typeof props.redirectPlayer === 'function' && !['EXPIRED', 'REFUND', ' '].includes(column['notificationType']) && (
                    <MenuItem>
                      <div style={{ paddingLeft: "10px" }} onClick={() => navigate(`/users/player/${column?.loginId}`)}>All Player</div>
                    </MenuItem>
                  )} */}
                  {typeof props.promoCodeFn === 'function' && (
                    <MenuItem>
                      <div
                        onClick={() =>
                          typeof props.promoCodeFn === "function" &&
                          props.promoCodeFn(column._id)
                        }
                        style={{ marginLeft: '10px', marginBottom: '2px' }}
                      >
                        Apply Promo Code
                      </div>
                    </MenuItem>
                  )}
                  {column?.promo_code_assign && typeof props.deletePromoCodeFn === 'function' && (
                    <MenuItem>
                      <div
                        onClick={() =>
                          typeof props.deletePromoCodeFn === "function" &&
                          props.deletePromoCodeFn(column._id)
                        }
                        style={{ marginLeft: '10px', marginBottom: '2px' }}
                      >
                        Delete Promo Code
                      </div>
                    </MenuItem>
                  )}
                  {typeof props.editFn === "function" && (
                    <MenuItem>
                      <div
                        onClick={() =>
                          typeof props.editFn === "function" &&
                          props.editFn(column._id)
                        }
                        style={{ marginLeft: '10px', marginBottom: '2px' }}
                      >
                        Edit
                      </div>
                    </MenuItem>
                  )}
                  {typeof props.deleteFn === 'function' && (
                    <MenuItem>
                      <div
                        onClick={() =>
                          typeof props.deleteFn === 'function' &&
                          props.deleteFn(column._id)
                        }
                        style={{ marginLeft: '10px', marginBottom: '2px' }}
                      >
                        Delete
                      </div>
                    </MenuItem>
                  )}
                  {typeof props.topicEditFn === "function" && (
                    <MenuItem>
                      <div
                        onClick={() =>
                          typeof props.topicEditFn === "function" &&
                          props.topicEditFn(column.id)
                        }
                        style={{ marginLeft: '10px', marginBottom: '2px' }}
                      >
                        Edit
                      </div>
                    </MenuItem>
                  )}
                  {typeof props.topicDeleteFn === 'function' && (
                    <MenuItem>
                      <div
                        onClick={() =>
                          typeof props.topicDeleteFn === 'function' &&
                          props.topicDeleteFn(column.id) && setDropIndex(0)
                        }
                        style={{ marginLeft: '10px', marginBottom: '2px' }}
                      >
                        Delete
                      </div>
                    </MenuItem>
                  )}
                  {typeof props.topicNotifyFn === 'function' && (
                    <MenuItem>
                      <div
                        onClick={() =>
                          typeof props.topicNotifyFn === 'function' &&
                          props.topicNotifyFn(column.id) && setDropIndex(0)
                        }
                        style={{ marginLeft: '10px', marginBottom: '2px' }}
                      >
                        Notify
                      </div>
                    </MenuItem>
                  )}
                </Menu>
              )}
            </TableCell>
            }
          </TableRow>
          {typeof props.dropFn === 'function' &&
            isOpen &&
            dropIndex === index && (
              <TableRow>
                <TableCell colSpan={7}>
                  <Table>
                    <TableHead className="innerdata">
                      <TableRow>
                        <TableCell>From</TableCell>
                        <TableCell>{dropData.email}</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>Subject</TableCell>
                        <TableCell>{dropData.subject}</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>Description</TableCell>
                        <TableCell>{dropData.description}</TableCell>
                      </TableRow>
                    </TableHead>
                  </Table>

                  <div className="text-end">
                    <a
                      className="btn btn-success mt-2"
                      href={`mailto:?subject=Regarding your Query on Quintic Roll App : ${dropData.subject}&to=${dropData.email}&body=${dropData.description} :`}
                    >
                      Reply
                    </a>
                  </div>
                </TableCell>
              </TableRow>
            )}
        </>
      ))}
      {props.setData.length === 0 && (
        <TableRow>
          <TableCell className="text-center" colSpan={5}>
            <span>No Record found</span>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};
