import { CTableHeaderCell } from "@coreui/react";
import { TableSortLabel } from "@mui/material";
import { ColumnsType, Order } from "../../types";

export interface TableHeadData {
  setHead: ColumnsType[];
  order: string;
  orderBy: string;
  sortFn: (event: React.MouseEvent<unknown>, id: string) => void;
  checkFn?: any;
  onSelectAllClick?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isAllSelected?: boolean;
  isIntermediateState?: boolean;
}

export interface SortingData {
  id: string;
  name: string;
  email: string;
  subscriptionType: string;
  notificationType: string;
  createdAt: Date;
  updatedAt?: Date;
}

export const TableHeadElement: React.FunctionComponent<TableHeadData> = (
  props: TableHeadData
) => {
  return (
    <>
        {props.setHead &&
          props.setHead.map((column: ColumnsType) => (
            <CTableHeaderCell
              key={column.id}
              style={column?.style}
              align="left"
              onClick={(event) => column.sort && props.sortFn(event, column.id)}
            >
              {column.sort && (
                <TableSortLabel
                  active={props.orderBy === (column.id as keyof SortingData)}
                  direction={props.order as Order}
                >
                  {column.label}
                </TableSortLabel>
              )}
              {!column.sort && column.label}
            </CTableHeaderCell>
          ))}
    </>
  );
};
