import axios, { AxiosError, AxiosRequestConfig } from "axios";
import type { BaseQueryFn } from "@reduxjs/toolkit/query";

// Base Url For Application
export const BASE_URL = `${process.env.REACT_APP_API_URL}/api/admin` as string;

export const axiosBaseQuery =
  (
    // Set Default Base Url
    { baseUrl }: { baseUrl: string } = { baseUrl: BASE_URL }
  ): BaseQueryFn<
    {
      url: string;
      method: AxiosRequestConfig["method"];
      params?: AxiosRequestConfig["params"];
      body?: AxiosRequestConfig["data"];
    },
    unknown,
    unknown
  > =>
    async ({ url, method, params, body }) => {
      try {
        const result = await axios({
          url: baseUrl + url,
          method,
          data: body,
          params,
          headers: localStorage.accessToken
            ? {
              DbAuthToken: localStorage.accessToken,
            }
            : {},
        });
        return { data: result.data };
      } catch (axiosError) {
        let err = axiosError as AxiosError;

        return {
          error: {
            status: err.response?.status,
            data: err.response?.data || err.message,
          },
        };
      }
    };
