import { createApi } from "@reduxjs/toolkit/query/react";
import { QbrUser, Response } from "../../types";
import { axiosBaseQuery } from "./AxiosBaseQuery";

export interface LoginRequest {
  email: string;
  password: string;
}

export interface ForgotPasswordRequest {
  email: string;
}

export interface ChangePasswordRequest {
  password: string;
  confirm_password: string;
}

export interface ResetPasswordRequest {
  password: string;
  confirm_password: string;
  email?: string;
}

export interface LoginResponse extends QbrUser {}
export const LoginApi = createApi({
  reducerPath: "loginAPI",
  baseQuery: axiosBaseQuery(),
  tagTypes: [
    "LoginTag",
    "ForgotPasswordTag",
    "ResetPasswordTag",
    "ChangePasswordTag",
  ],
  endpoints: (builder) => ({
    doLogin: builder.mutation<Response<LoginResponse>, LoginRequest>({
      query: (mtuser: LoginRequest) => ({
        url: `/auth/login`,
        method: "POST",
        body: mtuser,
      }),
      invalidatesTags: ["LoginTag"],
      transformResponse: (result: Response<LoginResponse>, _meta, _arg) => {
        if (result.status == 200) {
          localStorage.setItem("accessToken", result.response.token);
          localStorage.setItem("user", JSON.stringify(result.response));
        }
        return result;
      },
    }),
    forgotPassword: builder.mutation<
      Response<LoginResponse>,
      ForgotPasswordRequest
    >({
      query: (mtuser: ForgotPasswordRequest) => ({
        url: `/auth/forgot-password`,
        method: "POST",
        body: mtuser,
      }),
      invalidatesTags: ["ForgotPasswordTag"],
    }),
    resetPassword: builder.mutation<
      Response<LoginResponse>,
      ResetPasswordRequest
    >({
      query: (mtuser: ResetPasswordRequest) => ({
        url: `/auth/reset-password/${mtuser.email}`,
        method: "PATCH",
        body: mtuser,
      }),
      invalidatesTags: ["ResetPasswordTag"],
    }),
    changePassword: builder.mutation<
      Response<LoginResponse>,
      ChangePasswordRequest
    >({
      query: (mtuser: ChangePasswordRequest) => ({
        url: `/auth/change-password`,
        method: "POST",
        body: mtuser,
      }),
      invalidatesTags: ["ChangePasswordTag"],
    }),
  }),
});

export const {
  useDoLoginMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useChangePasswordMutation,
} = LoginApi;
