import React from "react";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  CContainer,
  CHeader,
  CAvatar,
  CHeaderBrand,
  CHeaderDivider,
  CHeaderNav,
  CHeaderToggler,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilMenu } from "@coreui/icons";
import { AppBreadcrumb } from "./index";
import { AppHeaderDropdown } from "../components/header/index";
import logo from "../assets/images/avatars/acd_logo.png";
import "react-toastify/ReactToastify.min.css";
import { toggleSidebar } from "../redux-services/slices/SidebarSlice";
export interface HeaderProps {
  isLoading?: boolean;
}


const AppHeader = () => {
  const [show, setShow] = useState<Boolean>(true);
  useEffect(() => {
    dispatch(toggleSidebar(show as any))
  }, [show]);
  const dispatch = useDispatch();

  // const sidebarShow = useSelector((state: any) => state.sidebarShow);
  return (
    <CHeader position="sticky" className="mb-4">
      <CContainer fluid>
        <CHeaderToggler
          className="ps-1"
          onClick={() => setShow(!show)}
        // onClick={() => dispatch({ type: "set", sidebarShow: !sidebarShow })}
        >
          <CIcon icon={cilMenu} size="lg" />
        </CHeaderToggler>
        <CHeaderBrand className="mx-auto d-md-none">
          <CAvatar
            className="sidebar-brand-full"
            src={logo}
            size="md"
          />
        </CHeaderBrand>
        <CHeaderNav className="d-none d-md-flex me-auto"></CHeaderNav>
        <CHeaderNav className="ms-3" >
          <AppHeaderDropdown />
        </CHeaderNav>
      </CContainer>
      <CHeaderDivider />
      <CContainer fluid>
        <AppBreadcrumb />
      </CContainer>
    </CHeader>
  );
};

export default AppHeader;
