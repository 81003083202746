import { createApi } from "@reduxjs/toolkit/query/react";
import { PageMeta, PaginatedData, PromoCode, Response, SocialListData } from "../../types";
import { axiosBaseQuery } from "./AxiosBaseQuery";

export interface EnquiresState {
  name: string;
  email?: string;
  subscriptionType: string;
}

export const EnquiresApi = createApi({
  reducerPath: "enquiresAPI",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["EnquiryList", "EnquiryListById"],
  endpoints: (builder) => ({
    enquiryList: builder.query<
      Response<PaginatedData<SocialListData[]>>,
      PageMeta
    >({
      // Getting enquiry query parameters
      query: (pageData: PageMeta) => ({
        url: `/users`,
        method: "GET",
        params: {
          limit: pageData.per_page ?? Number(process.env.REACT_APP_PER_PAGE),
          page: pageData.page ?? 1,
          order: pageData.order ?? process.env.REACT_APP_ORDER,
          order_by: pageData.order_by ?? process.env.REACT_APP_ORDER_BY,
          search: pageData.searchText,
          startDate: pageData.startDate ?? "",
          endDate: pageData.endDate ?? "",
        },
      }),
      providesTags: ["EnquiryList"],
    }),

    // Getting enquiry query parameters by ID
    enquiryListById: builder.query<
      Response<SocialListData>,
      string | undefined
    >({
      query: (id: string | undefined) => ({
        url: `/users/${id}`,
        method: "GET",
      }),
      providesTags: ["EnquiryListById"],
    }),

    // Creating enquiry mutation
    addEnquiry: builder.mutation<Response<FormData>, FormData>({
      query: (mtuser: FormData) => ({
        url: `/users`,
        method: "POST",
        body: mtuser,
      }),
      invalidatesTags: ["EnquiryList"],
    }),

    bulkdeleteEnqueries: builder.mutation<
      Response<EnquiresState[]>,
      string[] | undefined
    >({
      query: (ids: string[]) => ({
        url: `/users/bulk-delete/`,
        method: "DELETE",
        body: ids,
      }),
      invalidatesTags: ["EnquiryList"],
    }),

    // Updating Enquires mutation
    editEnquiry: builder.mutation<Response<FormData>, FormData>({
      query: (data: FormData) => ({
        url: `/users/${data.get("id")}`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["EnquiryList"],
    }),

    deleteEnquires: builder.mutation<
      Response<EnquiresState>,
      any | undefined
    >({
      query: (id: any | undefined) => ({
        url: `/users/${id}`,
        method: "DELETE",
        body: id
      }),
      invalidatesTags: ["EnquiryList"],
    }),

    // Getting enquires query parameters
    getEnquiries: builder.query<Response, void>({
      query: () => ({
        url: `/users/get-users`,
        method: "GET",
      }),
      providesTags: ["EnquiryList"],
    }),

    applyPromoCode: builder.mutation<Response<FormData>, PromoCode>({
      query: (data: PromoCode) => ({
        url: `/users/apply_promo_code`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["EnquiryList"],
    }),

    getPromoCodeDetail: builder.query<Response, string | any>({
      query: (id: string | any) => ({
        url: `/users/user_promo_code/${id}`,
        method: "GET",
      }),
      providesTags: ["EnquiryList"],
    }),

    deletePromoCode: builder.mutation<Response<FormData>, string | any>({
      query: (id: string | any) => ({
        url: `/users/promo_code/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["EnquiryList"],
    }),

  }),
});
export const {
  useEnquiryListQuery,
  useEnquiryListByIdQuery,
  useAddEnquiryMutation,
  useEditEnquiryMutation,
  useDeleteEnquiresMutation,
  useGetEnquiriesQuery,
  useBulkdeleteEnqueriesMutation,
  useApplyPromoCodeMutation,
  useGetPromoCodeDetailQuery,
  useDeletePromoCodeMutation,
} = EnquiresApi;
