import { cilUser } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
} from "@coreui/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Dispatch } from "redux";
import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";
import { GetLoader, useResetPasswordMutation } from "../../../redux-services";
import AlertBox from "../../../components/AlertBox/AlertBox";
import { Box, FormHelperText } from "@mui/material";
import loadingImage from "../../../assets/images/loader.svg";
import { setServerValidations } from "../../../helpers/helpers";

export interface HeaderProps {
  isLoading?: boolean;
}

export interface ResetPasswordData {
  password: string;
  confirm_password: string;
  email?: string;
}

const ResetPassword: React.FunctionComponent = (props) => {
  const isLoading = useSelector(GetLoader);
  const navigate = useNavigate();
  let location = useLocation();

  const initialFrom: ResetPasswordData = {
    password: "",
    confirm_password: "",
    email: "",
  };

  const [resetPassword, result] = useResetPasswordMutation();
  const [formValidation, setFormValidation] =
    useState<ResetPasswordData>(initialFrom);

  const [user, setUser] = useState<ResetPasswordData>(initialFrom);

  const { email, id, token } = useParams<string>();
  const [validated, setValidated] = useState(false);
  const [isRevealPwd, setIsRevealPwd] = useState<boolean>(false);
  const [isConfRevealPwd, setIsConfRevealPwd] = useState<boolean>(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      setValidated(true);
      return false;
    }

    if (form.checkValidity()) {
      setFormValidation({
        ...formValidation,
        ...{
          password: "",
          confirm_password: "",
        },
      });
      user.email = email;
      resetPassword(user);
    }
  };
  const isShow = (show: boolean, filedType: string) => {
    if (filedType === "password") {
      const status = show !== isRevealPwd;
      setIsRevealPwd(status);
    } else {
      const status = show !== isConfRevealPwd;
      setIsConfRevealPwd(status);
    }
  };

  useEffect(() => {
    if (result?.isSuccess && result?.data?.status === 422) {
      const setError = setServerValidations(result?.data?.error);
      setFormValidation({
        ...formValidation,
        ...setError,
      });
    }
  }, [result?.isSuccess, user]);

  if (result?.isSuccess && result?.data?.status === 200) {
    {
      /* <Navigate to="/login" state={{ from: location }} replace />; */
    }
    navigate("/login", { replace: true, state: location });
  }

  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={5}>
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                  <CForm
                    noValidate
                    validated={validated}
                    onSubmit={handleSubmit}
                  >
                    <AlertBox />
                    <h3>Reset Password</h3>
                    <p></p>
                    <CInputGroup className="mb-4">
                      <CInputGroupText>
                        <CIcon icon={cilUser} />
                      </CInputGroupText>
                      <CFormInput
                        type={isRevealPwd ? "text" : "password"}
                        placeholder="New Password"
                        name="password"
                        feedbackInvalid="Please provide a new password."
                        required
                        onChange={handleChange}
                      />
                      <span onClick={() => isShow(true, "password")}>
                        {isRevealPwd ? (
                          <VisibilityOffOutlined />
                        ) : (
                          <VisibilityOutlined />
                        )}
                      </span>
                      {/* <FormHelperText error={showError}>
                        {"zdddddddddddddddd"}
                      </FormHelperText> */}
                      {formValidation.password && (
                        <div className="text-danger invalid-server-feedback">
                          {formValidation.password}
                        </div>
                      )}
                    </CInputGroup>
                    <CInputGroup className="mb-4">
                      <CInputGroupText>
                        <CIcon icon={cilUser} />
                      </CInputGroupText>
                      <CFormInput
                        type={isConfRevealPwd ? "text" : "password"}
                        placeholder="Confirm New Password"
                        name="confirm_password"
                        feedbackInvalid="Please provide a confirm new password."
                        required
                        onChange={handleChange}
                      />
                      <span onClick={() => isShow(true, "confirm")}>
                        {isConfRevealPwd ? (
                          <VisibilityOffOutlined />
                        ) : (
                          <VisibilityOutlined />
                        )}
                      </span>
                      {formValidation.confirm_password && (
                        <div className="text-danger invalid-server-feedback">
                          {formValidation.confirm_password}
                        </div>
                      )}
                    </CInputGroup>
                    <CRow>
                      <CCol xs={6}>
                        <CButton type="submit" color="primary" className="px-4">
                          Submit
                        </CButton>
                      </CCol>
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
      <Box className={`loader-outer ${isLoading ? "addloader" : ""}`}>
        <img src={loadingImage} />
      </Box>
      <ToastContainer position="top-right" newestOnTop />
    </div>
  );
};

export default ResetPassword;
