import React, { useEffect } from 'react'

const AppStoreRedirect = () => {

  useEffect(() => {
    let appStoreRoute = "https://apps.apple.com/in/app/quintic-ball-roll/id6484263964";
    window.open(appStoreRoute, "_self");
  }, [])

  return (
    <div className="loader-container">
      <div className="spinner"></div>
    </div>
  )
}

export default AppStoreRedirect