import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { setAuth } from "./redux-services";
import React from 'react';

const PrivateRoute = (props: any) => {
  const dispatch = useDispatch();
  let location = useLocation();
  const userAccessToken = localStorage.accessToken;
  useEffect(() => {
    dispatch(setAuth())
  }, [])
  if (!userAccessToken) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  return props.children;
};

export default PrivateRoute;
