import { useSelector } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import "./App.css";
import "./assets/scss/style.scss";
import DefaultLayout from "./layout/DefaultLayout";
import ForgotPassword from "./views/public-pages/login/Forgot-Password";
import Login from "./views/public-pages/login/Login";
import ResetPassword from "./views/public-pages/login/Reset-Password";
import AppStoreRedirect from "./views/appStoreRedirect/AppStoreRedirect";


function App() {
  const { isAuthenticated } = useSelector((state: any) => state.auth);
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/iosapp" element={<AppStoreRedirect />} />
        <Route path="/login" element={isAuthenticated? <Navigate to={"/dashboard"} /> : <Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password/:email" element={<ResetPassword />} />
        <Route path="/" element={<Navigate to={isAuthenticated? "/dashboard" : "/login"} />} />
        <Route path="*" element={<DefaultLayout />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;