import * as React from 'react';
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CFormLabel,
  CInputGroup,
  CRow,
} from '@coreui/react';
import {
  Alert,
  Backdrop,
  Button,
  debounce,
  Divider,
  Fade,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  SelectChangeEvent,
  StyledEngineProvider,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { saveAs } from 'file-saver';
import { useMemo, useState } from 'react';
import { SearchElement } from '../../components/Search/Search';
import { TableBodyElement } from '../../components/TableBody/TableBodyElement';
import { TableHeadElement } from '../../components/TableBody/TableHeadElement';
import {
  useApplyPromoCodeMutation,
  useBulkdeleteEnqueriesMutation,
  useDeleteEnquiresMutation,
  useDeletePromoCodeMutation,
  useEnquiryListQuery,
  useGetPromoCodeDetailQuery,
} from '../../redux-services';
import { ColumnsType, InquiryList, Order, PageMeta } from '../../types';
import Box from '@mui/material/Box';
import ConfirmBox from '../../components/ConfirmBox/ConfirmBox';
import { DeleteForeverRounded } from '@mui/icons-material';
import { DateRangePicker } from 'rsuite';
import subDays from 'date-fns/subDays';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import addDays from 'date-fns/addDays';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import addMonths from 'date-fns/addMonths';
import 'rsuite/dist/rsuite.css';

const style = {
  position: 'absolute' as 'absolute',
  top: '40%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  borderRadius: 2,
  p: 2,
};

const predefinedRanges = [
  {
    label: 'Today',
    value: [new Date(), new Date()],
    placement: 'left',
  },
  {
    label: 'Yesterday',
    value: [addDays(new Date(), -1), addDays(new Date(), -1)],
    placement: 'left',
  },
  {
    label: 'This week',
    value: [startOfWeek(new Date()), endOfWeek(new Date())],
    placement: 'left',
  },
  {
    label: 'Last 7 days',
    value: [subDays(new Date(), 6), new Date()],
    placement: 'left',
  },
  {
    label: 'Last 30 days',
    value: [subDays(new Date(), 29), new Date()],
    placement: 'left',
  },
  {
    label: 'This month',
    value: [startOfMonth(new Date()), new Date()],
    placement: 'left',
  },
  {
    label: 'Last month',
    value: [
      startOfMonth(addMonths(new Date(), -1)),
      endOfMonth(addMonths(new Date(), -1)),
    ],
    placement: 'left',
  },
  {
    label: 'This year',
    value: [new Date(new Date().getFullYear(), 0, 1), new Date()],
    placement: 'left',
  },
  {
    label: 'Last year',
    value: [
      new Date(new Date().getFullYear() - 1, 0, 1),
      new Date(new Date().getFullYear(), 0, 0),
    ],
    placement: 'left',
  },
  {
    label: 'All time',
    value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date()],
    placement: 'left',
  },
  {
    label: 'Last week',
    closeOverlay: false,
    value: (value: any) => {
      const [start = new Date()] = value || [];
      return [
        addDays(startOfWeek(start, { weekStartsOn: 0 }), -7),
        addDays(endOfWeek(start, { weekStartsOn: 0 }), -7),
      ];
    },
    appearance: 'default',
  },
  {
    label: 'Next week',
    closeOverlay: false,
    value: (value: any) => {
      const [start = new Date()] = value || [];
      return [
        addDays(startOfWeek(start, { weekStartsOn: 0 }), 7),
        addDays(endOfWeek(start, { weekStartsOn: 0 }), 7),
      ];
    },
    appearance: 'default',
  },
];

// array for the column objects
const tableColumns: ColumnsType[] = [
  {
    id: 'id',
    sort: false,
    icon: 'hello',
    label: 'ID',
  },
  {
    id: 'name',
    sort: true,
    label: 'Name',
  },
  {
    id: 'email',
    sort: true,
    label: 'Email',
  },
  {
    id: 'purchaseDate',
    sort: true,
    label: 'Date Of Joining',
  },
  {
    id: 'renewalDate',
    sort: false,
    label: 'Renewal Date'
  },
  // {
  //   id: 'subscriptionType',
  //   sort: false,
  //   label: 'Subscription type',
  // },
  {
    id: 'notificationType',
    sort: false,
    label: 'Subscription Status',
  },
  {
    id: 'platform',
    sort: false,
    label: 'Platform'
  },
  {
    id: 'action',
    sort: false,
    label: '',
    style: { width: '5%' },
  },
];
//to represent date
// const dateLimit = new Date();

//specify the structure of data that is displayed in a table
export interface SortingData {
  name: string;
  email: string;
  purchaseDate: Date;
  subscriptionType: string;
}

//obj that repn state variable that stores the data of a message.
const ShowMessageData = {
  name: '',
  email: '',
  purchaseDate: '',
  subscriptionType: '',
  notificationType: '',
  createdAt: '',
  updatedAt: '',
};

let initialOrder: string = process.env.REACT_APP_ORDER as string;
//initialState is an object that represents the initial state of a component
const initialState: PageMeta = {
  //properties of initialState
  page: 1,
  per_page: Number(process.env.REACT_APP_PER_PAGE),
  searchText: '',
  order: process.env.REACT_APP_ORDER,
  order_by: 'createdAt',
};

function convertUnixToDateString(unixTimestamp: number) {
  // Create a Date object from the Unix timestamp (milliseconds)
  const date = new Date(unixTimestamp);

  // Define an array of month names
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  // Get the day, month, and year from the Date object
  const day = date.getDate();
  const month = monthNames[date.getMonth()];
  const year = date.getFullYear();

  // Format the date string as dd/mmm/yyyy
  const dateString = `${day.toString().padStart(2, '0')}/${month}/${year}`;

  return dateString;
}

export const Users: React.FunctionComponent = (props) => {
  // page state which tells the number of pages in user table
  const [page, setPage] = useState<number>(1);
  const [orderBy, setOrderBy] = useState<keyof SortingData>(
    (process.env.REACT_APP_ORDER_BY as keyof SortingData) || 'purchaseDate'
  );

  const [order, setOrder] = useState<Order>((process.env.REACT_APP_ORDER as Order) || 'desc');
  const [search, setSearch] = useState<string>('');
  const [rowsPerPage, setRowsPerPage] = useState<number>(
    initialState.per_page as number,
  );

  const [enquiriesListState, setEnquiriesListState] =
    useState<PageMeta>(initialState);
  const [selectedDate, setSelectedDate] = useState<{
    startDate: string;
    endDate: string;
  }>({ startDate: '', endDate: '' });
  const [confirmation, setConfirmation] = useState<boolean>(false);
  const [enquiryMessage, setEnquiryMessage] =
    useState<InquiryList>(ShowMessageData);
  const [isClose, setIsClose] = useState<boolean>(false);
  const [Id, setId] = useState<string>('');
  const [check, setCheck] = useState<boolean>(false);

  // Promo Code State
  const [showPromoCode, setShowPromoCode] = useState(false);
  const [formData, setFormData] = useState({
    id: "",
    promo_type: ""
  });

  const [promoError, setPromoError] = useState<{ status: boolean, data: any }>({
    status: false,
    data: {},
  });

  const { data: promoCodeDetail } = useGetPromoCodeDetailQuery(formData?.id, { skip: formData.id === '' });

  const [promoDeleteIsClose, setPromoDeleteIsClose] = useState(false);

  const [applyPromoCode, resultPromoCode] = useApplyPromoCodeMutation();

  const [deletePromoCode, deletePromoCodeRes] = useDeletePromoCodeMutation();

  //useEnquiryListQuery --> hook
  const { data } = useEnquiryListQuery({
    //param which do pagination, sorting, and filtering of the API call.
    per_page: enquiriesListState.per_page ?? rowsPerPage,
    page: enquiriesListState.page ?? page,
    // order: enquiriesListState.order ?? order,
    // order_by: enquiriesListState.order_by ?? orderBy,
    searchText: enquiriesListState.searchText ?? search,
    startDate: selectedDate.startDate ?? '',
    endDate: selectedDate.endDate ?? '',
  });

  
   // Sort the received data by purchaseDate (latest first)
   const sortedData = React.useMemo(() => {
    if (data?.response?.data) {
      return [...data.response.data].sort((a, b) => {
        const dateA = new Date((a as any).purchaseDate).getTime();
        const dateB = new Date((b as any).purchaseDate).getTime();
        return dateB - dateA; // Sort in descending order of purchaseDate
      });
    }
    return [];
  }, [data]);
  

  React.useEffect(() => {
    if (promoCodeDetail?.status === 200) {
      setFormData({
        ...formData,
        promo_type: promoCodeDetail?.response?.promo_type ?? '',
      })
    }
  }, [promoCodeDetail])

  React.useEffect(() => {
    if (resultPromoCode?.isSuccess && resultPromoCode?.data?.status === 200) {
      setFormData({ id: "", promo_type: "" });
      setShowPromoCode(false);
      setPromoError({
        status: false,
        data: {}
      })
    }
    if (resultPromoCode?.data?.status === 400) {
      let response: any = resultPromoCode?.data?.response;
      let date = convertUnixToDateString(response?.expired_date ?? 0);

      setPromoError({
        status: true,
        data: {
          name: response?.name ?? '',
          expire_date: date,
          promo_type: response?.promo_type === 1 ? 'Monthly' : 'Yearly',
        },
      });
    }
  }, [resultPromoCode]);

  React.useEffect(() => {
    if (promoError?.status) {
      setTimeout(() => {
        setPromoError({
          status: false,
          data: {}
        })
      }, 8000);
    }
  }, [promoError]);


  const confirmPromoDelete = (res: boolean) => {
    setPromoDeleteIsClose(res);
    if (res) {
      setPromoDeleteIsClose(false);
      deletePromoCode(Id);
    } else {
      setPromoDeleteIsClose(res);
    }
  };


  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage + 1);
    setEnquiriesListState({ ...enquiriesListState, page: newPage + 1 });
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setEnquiriesListState({
      ...enquiriesListState,
      per_page: parseInt(event.target.value),
      page: 1,
    });
    setPage(1);
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof SortingData,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    const newOrder = isAsc ? 'desc' : 'asc';
    setOrder(newOrder);
    setOrderBy(property);
    setEnquiriesListState({
      ...enquiriesListState,
      order: newOrder,
      order_by: property,
    });
  };

  const createSortHandler = (
    event: React.MouseEvent<unknown>,
    property: keyof SortingData,
  ) => {
    if (property !== ('action' as keyof SortingData)) {
      handleRequestSort(event, property);
    }
  };

  const searchHandler = useMemo(() => {
    return debounce((event: React.ChangeEvent<HTMLInputElement>) => {
      setSearch(event.target.value.toLowerCase());
    }, 800);
  }, []);

  const showMessage = (content: InquiryList) => {
    setConfirmation(true);
    setEnquiryMessage(content);
  };

  const handleClose = (res: boolean) => {
    setConfirmation(res);
    if (res) {
      setConfirmation(false);
    } else {
      setConfirmation(res);
    }
  };

  const handleApply = (
    event: React.ChangeEvent<HTMLInputElement>,
    picker: any,
  ) => {
    picker.element.val(
      picker.startDate.format('MM/DD/YYYY') +
      ' - ' +
      picker.endDate.format('MM/DD/YYYY'),
    );
    setSelectedDate({
      startDate: picker.startDate.format('MM/DD/YYYY'),
      endDate: picker.endDate.format('MM/DD/YYYY'),
    });
  };
  const handleCancel = (
    event: React.ChangeEvent<HTMLInputElement>,
    picker: any,
  ) => {
    picker.element.val('');
    setSelectedDate({
      startDate: '',
      endDate: '',
    });
  };

  function convertToCSV(data: any[]): string {
    const excludedFields = ['id', 'check', 'action'];
    const headers = tableColumns
      .filter((column) => !excludedFields.includes(column.id)) // ensures that the excluded fields are not included in the CSV headers.
      .map((column) => column.label) //map method is used to extract the label property (column label) from each remaining column object in the tableColumns array.
      .join(',');
    const rows = data.map((obj: any) =>
      tableColumns
        .filter((column) => !excludedFields.includes(column.id))
        .map((column) => obj[column.id])
        .join(','),
    );
    return headers + '\n' + rows.join('\n');
  }

  const exportHandler = () => {
    const jsonData = data?.response?.data;
    const csvData = convertToCSV(jsonData as any);
    const csvBlob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    saveAs(csvBlob, 'Users.csv');
  };

  const [deleteEnquires] = useDeleteEnquiresMutation();
  const [bulkdeleteSeo] = useBulkdeleteEnqueriesMutation();
  const confirmBoxClose = (res: boolean) => {
    setIsClose(res);
    if (res) {
      setIsClose(false);
      if (selected.length && !Id) {
        bulkdeleteSeo(selected);
        setSelected([]);
      } else {
        deleteEnquires(Id);
      }
    } else {
      setIsClose(res);
    }
  };

  const [selected, setSelected] = useState<string[]>([]);
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds =
        data?.response.data?.map((row: any) => {
          return row._id;
        }) ?? [];
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };
  const handleClick = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: string,
  ) => {
    if (event.target.checked) {
      setSelected((previousSelected) => [...previousSelected, id]);
    } else {
      setSelected((previousSelected) =>
        previousSelected.filter((selectedId) => selectedId !== id),
      );
    }
  };

  const DateFormat = React.useCallback((dateString: any) => {
    const year = dateString.getFullYear();
    const month = String(dateString.getMonth() + 1).padStart(2, '0');
    const day = String(dateString.getDate()).padStart(2, '0');
    return `${month}-${day}-${year}`;
  }, []);

  const handleDateChange = (date: any) => {
    if (date && date.length === 2) {
      setSelectedDate({
        startDate: DateFormat(date[0]),
        endDate: DateFormat(date[1]),
      });
    } else {
      setSelectedDate({
        startDate: '',
        endDate: '',
      });
    }
  };

  const handlePromoCode = (id: string) => {
    setShowPromoCode(true);
    setFormData({ ...formData, id: id });
  };

  const selectPromoCode = (event: (SelectChangeEvent | any)) => {
    setFormData({ ...formData, promo_type: event.target.value });
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    applyPromoCode(formData);
  };

  const filteredData = React.useMemo(() => {
    
    if (data?.response?.data) {
      const filtered = data.response.data.filter((item: any) => {
        const name = item?.name?.toLowerCase() || ''; // Ensure `name` exists, fallback to an empty string
        const email = item?.email?.toLowerCase() || ''; // Ensure `email` exists, fallback to an empty string
        const purchaseDate = new Date(item.purchaseDate);
  
        // Check if the item matches the search criteria
        const matchesSearch = name.includes(search.toLowerCase()) || email.includes(search.toLowerCase());
  
        // Convert selectedDate to Date objects for comparison
        const startDate = selectedDate.startDate ? new Date(selectedDate.startDate) : null;
        const endDate = selectedDate.endDate ? new Date(selectedDate.endDate) : null;
  
        // Check if the purchase date is within the selected date range
        const matchesDateRange =
          (!startDate || purchaseDate >= startDate) &&
          (!endDate || purchaseDate <= endDate);
  
        return matchesSearch && matchesDateRange;
      });
  
  
      // Sorting logic based on orderBy and order state
      return filtered.sort((a, b) => {
        let comparison = 0;
  
        if (orderBy === 'name') {
          comparison = (a as any).name.localeCompare((b as any).name);
        } else if (orderBy === 'email') {
          comparison = (a as any).email.localeCompare((b as any).email);
        } else if (orderBy === 'purchaseDate') {
          const dateA = new Date((a as any).purchaseDate).getTime();
          const dateB = new Date((b as any).purchaseDate).getTime();
          comparison = dateA - dateB; // Ascending order of dates
        }
  
        // Reverse the comparison for descending order
        return order === 'desc' ? -comparison : comparison;
      });
    }
    return [];
  }, [data, search, selectedDate, orderBy, order]);

  return (
    <CRow>
      <CCol xs={12}>
        <CCard className="mb-4">
          <CCardHeader
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <h5><strong>Users List</strong></h5>
            <button
              className="csv-button btn btn-danger"
              onClick={exportHandler}
            >
              Export CSV{''}
            </button>
            {selected.length > 1 && (
              <Tooltip title="Delete" arrow className="me-1 mt-1">
                <CButton
                  size="sm"
                  color="danger"
                  variant="outline"
                  onClick={() => setIsClose(true)}
                >
                  <DeleteForeverRounded />
                </CButton>
              </Tooltip>
            )}
          </CCardHeader>
          <CCardBody className="bg-transparent">
            <Box>
              <SearchElement
                searchFn={searchHandler}
                searchTag={'Search Users...'}
              />

              <CRow className="my-2 mb-2 ms-2 float-end">
                <CCol xs={'auto'} className="my-2 pe-0">
                  <CFormLabel htmlFor="search">Date Filter:</CFormLabel>
                </CCol>
                <CCol xs={'auto'}>
                  <CInputGroup>

                    <DateRangePicker
                      ranges={predefinedRanges as any}
                      placeholder="Select Date Range"
                      style={{ width: 200 }}
                      showOneCalendar
                      onChange={handleDateChange}
                    />

                  </CInputGroup>
                </CCol>
              </CRow>
            </Box>
            <StyledEngineProvider>
              <TableContainer component={Paper}>
                <Table
                  sx={{ minWidth: 650 }}
                  aria-label="simple table bordered"
                  className="table-bordered table"
                >
                  <TableHead>
                    <TableRow>
                      <TableHeadElement
                        setHead={tableColumns ? tableColumns : []}
                        order={order}
                        orderBy={orderBy}
                        sortFn={(event, id: string) =>
                          createSortHandler(event, id as keyof SortingData)
                        }
                        onSelectAllClick={handleSelectAllClick}
                        isAllSelected={
                          selected.length > 0 &&
                          selected.length === (data?.response.data?.length ?? 0)
                        }
                        isIntermediateState={
                          selected.length > 0 &&
                          selected.length < (data?.response.data?.length ?? 0)
                        }
                      />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableBodyElement
                      selectedColumns={[
                        'name',
                        'email',
                        'purchaseDate',
                        'renewalDate',
                        'subscriptionType',
                        // 'notificationType',
                        'platform'
                      ]}
                      setData={filteredData }
                      respondFn={(e) => console.log(e)}
                      // promoCodeFn={(id) => handlePromoCode(id)}
                      // deletePromoCodeFn={(id) => {
                      //   setPromoDeleteIsClose(true);
                      //   setId(id);
                      // }}
                      redirectPlayer={(e) => console.log(e)}
                      showAction={true}
                      styleFn={'25%'}
                      pageData={{
                        limit:
                          rowsPerPage ?? Number(process.env.REACT_APP_PER_PAGE),
                        page: data?.response?.page as number,
                      }}
                      selected={selected}
                      handleClick={handleClick}
                    />
                  </TableBody>
                </Table>
                <TablePagination
                  component="div"
                  rowsPerPageOptions={[20, 50, 100]}
                  count={data?.response.total ?? 0}
                  page={data?.response.page ?? 0}
                  onPageChange={handleChangePage}
                  rowsPerPage={
                    rowsPerPage ?? Number(process.env.REACT_APP_PER_PAGE)
                  }
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableContainer>
            </StyledEngineProvider>
          </CCardBody>
        </CCard>
      </CCol>
      {showPromoCode &&
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={showPromoCode}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade in={showPromoCode}>
            <Box sx={style}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Typography id="transition-modal-title" variant='h5' className='inner-headings' component="h2" style={{ color: "black" }}>
                  Apply Promo Code
                </Typography>
                <HighlightOffIcon className="closeicon" onClick={() => { setShowPromoCode(false); setFormData({ id: "", promo_type: "" }) }} />
              </div>
              {promoError?.status &&
                <Alert severity="error" className='mt-2'>
                  User Currently using "{promoError?.data?.name}" {promoError?.data?.promo_type} promo code, which is expire on the {promoError?.data?.expire_date}.
                </Alert>
              }
              <Box className="modalBody">
                <Divider sx={{ marginBottom: 3 }} />
                <form onSubmit={handleSubmit} >
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Box sx={{ minWidth: 120 }}>
                        <FormControl fullWidth size='small'>
                          <InputLabel id="demo-simple-select-label">Select Promo Code *</InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={formData.promo_type}
                            label="Select Promo Code"
                            required
                            onChange={selectPromoCode}
                            renderValue={() => formData.promo_type !== "" ? formData.promo_type.charAt(0).toUpperCase() + formData.promo_type.slice(1) + ' Promo Code' : ""}
                          >
                            <MenuItem value={'monthly'} selected={formData.promo_type === 'monthly'}>Monthly Promo Code</MenuItem>
                            <MenuItem value={'yearly'} selected={formData.promo_type === 'yearly'}>Yearly Promo Code</MenuItem>
                          </Select>
                        </FormControl>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        className="save-btn"
                        onSubmit={handleSubmit}
                        style={{ marginRight: '10px' }}
                      >
                        Submit
                      </Button>
                      <Button
                        variant="contained"
                        color="secondary"
                        className="close-btn"
                        style={{ backgroundColor: "red" }}
                        onClick={() => { setShowPromoCode(false); setFormData({ id: "", promo_type: "" }) }}
                      >
                        Cancel
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </Box>
            </Box>
          </Fade>
        </Modal>
      }
      <ConfirmBox
        title="User Confirmation"
        message="Are you sure, you want to delete this user record?"
        open={isClose}
        onClose={confirmBoxClose}
      />
      <ConfirmBox
        title="User Confirmation"
        message="Are you sure, you want to delete this user Promo Code?"
        open={promoDeleteIsClose}
        onClose={confirmPromoDelete}
      />
    </CRow>
  );
};
