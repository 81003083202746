import React from "react";
import { CFooter } from "@coreui/react";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import { GetLoader } from "../redux-services";
import loadingImage from "../assets/images/loader.svg";

const AppFooter = () => {
  const date = new Date();
  const year = date.getFullYear();
  const isLoading = useSelector(GetLoader);
  return (
    <CFooter>
      <div>
        <span className="ms-1">&copy; {year} ACD.</span>
      </div>
      <Box className={`loader-outer ${isLoading ? "addloader" : ""}`}>
        <img src={loadingImage} />
      </Box>
    </CFooter>
  );
};

export default React.memo(AppFooter);
