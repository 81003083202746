import React from "react";
import { createRoot } from "react-dom/client";
import { Provider, useDispatch } from "react-redux";
import { store } from "./app/store";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import {
  removeAlert,
  setAlert,
  setAuthLogout,
  setLoader,
} from "./redux-services";
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { Response } from "./types";

const Interceptor: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const showAlert = (error = false, message = "") => {
    dispatch(
      setAlert({
        type: error ? "error" : "success",
        message,
      })
    );
    setTimeout(() => {
      dispatch(removeAlert(0));
    }, 5000);
  };

  axios.interceptors.request.use(
    (request: AxiosRequestConfig): AxiosRequestConfig => {
      dispatch(setLoader({ isLoading: true }));
      return request;
    },
    (error) => error
  );

  axios.interceptors.response.use(
    (response: AxiosResponse): AxiosResponse => {
      if (response?.data?.status === 401) {
        localStorage.clear();
        dispatch(setAuthLogout());
        window.location.reload();
      }
      if (response.config.method != "get") {
        showAlert(response?.data?.status !== 200, response.data?.message);
      }
      dispatch(setLoader({ isLoading: false }));
      return response;
    },
    (error: AxiosError<Response>): AxiosError => {
      dispatch(setLoader({ isLoading: false }));
      showAlert(true, error.response?.data.message ?? error.message);
      if (error.response?.status === 401) {
        localStorage.clear();
        dispatch(setAuthLogout());
        window.location.reload();
      }
      return error.response as any;
    }
  );
  return <></>;
  };

const container = document.getElementById("root")!;
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Interceptor />
      <App />
    </Provider>
  </React.StrictMode>
);
reportWebVitals();
