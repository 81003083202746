import { cilUser } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
} from "@coreui/react";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Dispatch } from "redux";

import {
  useDoLoginMutation,
  useForgotPasswordMutation,
} from "../../../redux-services";
import AlertBox from "../../../components/AlertBox/AlertBox";

export interface ForgotPasswordData {
  email: string;
}

const ForgotPassword: React.FunctionComponent = (props) => {
  const navigate = useNavigate();
  const dispatch: Dispatch<any> = useDispatch();

  const [forgotPassword, result] = useForgotPasswordMutation();
  
  const [user, setUser] = useState<ForgotPasswordData>({ email: "" });

  const [validated, setValidated] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      setValidated(true);
      return false;
    }

    if (form.checkValidity()) {
      forgotPassword(user);

    }
  };
  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={6}>
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                  <CForm
                    noValidate
                    validated={validated}
                    onSubmit={handleSubmit}
                  >
                    <h3>Forgot Password</h3>
                    <AlertBox />                    
                    <CInputGroup className="mb-3">
                      <CInputGroupText>
                        <CIcon icon={cilUser} />
                    </CInputGroupText>
                    <CFormInput
                        type="email"
                        placeholder="Enter Email"
                        name="email"
                        feedbackInvalid="Please provide a valid email."
                        required
                        onChange={handleChange}
                      />
                    </CInputGroup>
                    <CRow>
                      <CCol xs={7}>
                        <CButton type="submit" color="primary" className="px-4">
                          Send Reset Password Link
                        </CButton>
                      </CCol>
                      <CCol xs={5} className="text-end">
                        <Link to={"/login"}>Have an account? Login</Link>
                      </CCol>
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};

export default ForgotPassword;
