import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import { LoginApi } from "../redux-services/api/LoginApi";
import AlertBoxReducer from "../redux-services/slices/AlertBoxSlice";
import AuthReducer from "../redux-services/slices/AuthSlice";
import counterReducer from "../features/counter/counterSlice";
import { EnquiresApi } from "../redux-services/api/UsersApi";
// import { SupportApi } from "../redux-services/api/SupportApi";
import { DashboardApi } from "../redux-services/api/DashboardApi";
import Sidebareducer from "../redux-services/slices/SidebarSlice";
// import { ManageUIApi, ObjectDetectionApi, PlayerApi, ProductsApi, TopicApi } from "../redux-services";

export const store = configureStore({
  reducer: {
    sidebar: Sidebareducer,
    counter: counterReducer,
    alertbox: AlertBoxReducer,
    auth: AuthReducer,
    [LoginApi.reducerPath]: LoginApi.reducer,
    [EnquiresApi.reducerPath]: EnquiresApi.reducer,
    // [SupportApi.reducerPath]: SupportApi.reducer,
    [DashboardApi.reducerPath]: DashboardApi.reducer,
    // [ManageUIApi.reducerPath]: ManageUIApi.reducer,
    // [ObjectDetectionApi.reducerPath]: ObjectDetectionApi.reducer,
    // [TopicApi.reducerPath]: TopicApi.reducer,
    // [ProductsApi.reducerPath]: ProductsApi.reducer,
    // [PlayerApi.reducerPath]: PlayerApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    })
      .concat(LoginApi.middleware)
      .concat(EnquiresApi.middleware)
      // .concat(SupportApi.middleware)
      .concat(DashboardApi.middleware)
      // .concat(ManageUIApi.middleware)
      // .concat(ObjectDetectionApi.middleware)
      // .concat(TopicApi.middleware)
      // .concat(ProductsApi.middleware)
      // .concat(PlayerApi.middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
